import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["diffSummary", "link", "diffShare", "emptyState", "errorState", "diffState", "fileButton", "urlButton", "title"]

  connect() {
    this.diffUrlValue = this.element.dataset.diffOrchestratorDiffUrlValue

    const hashMatch = window.location.hash.substr(1).match(/diff-([a-f0-9-]+)/)
    if (hashMatch) {
      const diffId = hashMatch[1]
      document.dispatchEvent(new CustomEvent("diff:load"))

      this.pollDiff(diffId)
    }

    const self = this
    document.addEventListener("file:valid", (event) => {
      self[`${event.detail.position}File`] = event.detail.spec
      if (self._checkFiles()) {
        self.fileButtonTarget.disabled = false
      }
    })

    document.addEventListener("url:valid", (event) => {
      self[`${event.detail.position}Url`] = event.detail.spec
      if (self._checkUrls()) {
        self.urlButtonTarget.disabled = false
      }
    })

    document.addEventListener("file:invalid", (event) => {
      self[`${event.detail.position}File`] = null
      self.fileButtonTarget.disabled = true
    })

    document.addEventListener("url:invalid", (event) => {
      self[`${event.detail.position}Url`] = null
      self.urlButtonTarget.disabled = true
    })
  }

  _checkFiles() {
    return this.previousFile && this.currentFile
  }

  _checkUrls() {
    return this.previousUrl && this.currentUrl
  }

  callFileDiff() {
    const self = this

    if (!self._checkFiles()) {
      document.dispatchEvent(new CustomEvent("diff:missingFile"))
    } else {
      document.dispatchEvent(new CustomEvent("diff:load"))

      Promise.all([
        self.previousFile.text(),
        self.currentFile.text()
      ]).then(([previous_file, current_file]) => {
        const payload = {
          previous_definition: previous_file,
          definition: current_file,
          expires_at: this._expirationDate()
        }

        self._callDiffWith(payload)
      })
    }
  }

  callUrlDiff() {
    const self = this

    if (!this._checkUrls()) {
      document.dispatchEvent(new CustomEvent("diff:missingUrl"))
    } else {
      document.dispatchEvent(new CustomEvent("diff:load"))

      const payload = {
        previous_url: self.previousUrl,
        url: self.currentUrl,
        expires_at: this._expirationDate()
      }

      self._callDiffWith(payload)
    }
  }

  pollDiff(id) {
    const self = this
    setTimeout(() => {
      this._callApi(`${self.diffUrlValue}/${id}?${new URLSearchParams("formats[]=html")}`, "GET", null).then((response) => {
        switch (response?.status) {
        case 202:
          self.pollDiff(id)
          break
        case 200:
          response.json().then(body => self._showResults(body))
          break
        case 404:
          history.replaceState({}, document.title, window.location.href.split("#")[0])
          throw new Error("This diff doesn't seem to exist.")
        default:
          throw new Error("We couldn't retrieve a diff. Please try again later.")
        }
      }).catch(error => self._showError(error))
    }, 2000)
  }

  _callDiffWith(payload) {
    const self = this

    self._callApi(self.diffUrlValue, "POST", JSON.stringify(payload)).then((response) => {
      switch (response?.status) {
      case 422:
        response.json().then((body) => {
          self._showError(body)
        })
        break
      case 404:
      case 400:
        throw new Error("File doesn't exist")
      case 201:
        response.json().then((body) => {
          self.pollDiff(body.id)
        })
        break
      default:
        throw new Error("We couldn't create a diff with the provided definitions. Please try again later.")
      }
    }).catch((error) => {
      self._showError(error)
    })
  }

  _callApi(url, method, body = null) {
    return fetch(url, {
      method: method,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: body
    })
  }

  _showResults(result) {
    this._clearResults()
    document.dispatchEvent(new CustomEvent("diff:results"))

    if (!result.html) {
      this.diffSummaryTarget.append(document.importNode(this.emptyStateTarget.content, true))
    } else {
      document.dispatchEvent(new CustomEvent("diff:hasChanges"))

      this.diffSummaryTarget.append(document.importNode(this.diffStateTarget.content, true))
      this.diffSummaryTarget.insertAdjacentHTML("beforeend", result.html)
      window.location.hash = `diff-${result.id}`
      const currentUrl = window.location.href
      history.pushState({path: currentUrl}, "", currentUrl)
      this.diffSummaryTarget.querySelector(".share-link").setAttribute("href", currentUrl)
      const title = result.current_version_title || "API"
      this.titleTarget.textContent = `${title} structure has changed`

      if (!result.breaking) {
        this.diffSummaryTarget.querySelector(".label[data-label-status='breaking'").remove()
      }
    }
  }

  _showError(error) {
    this._clearResults()
    document.dispatchEvent(new CustomEvent("diff:error"))
    this.diffSummaryTarget.append(document.importNode(this.errorStateTarget.content, true))
    const title = this.diffSummaryTarget.querySelector(".results__diff-title")
    const errorList = this.diffSummaryTarget.querySelector(".results__diff-errors")
    if (typeof error === "object") {
      title.textContent = error.message

      if (error.errors) {
        for (const [attr, message] of Object.entries(error.errors)) {
          const text = this._humanAttributeError(attr, message)
          const node = document.createElement("li")
          node.appendChild(document.createTextNode(text))
          errorList.appendChild(node)
        }
      }
    } else {
      const node = document.createElement("li")
      node.appendChild(document.createTextNode(error))
      errorList.appendChild(node)
    }
  }

  _clearResults() {
    this.diffSummaryTarget.replaceChildren()
    this.fileButtonTarget.disabled = true
    this.urlButtonTarget.disabled = true
  }

  _humanAttributeError(attribute, messages) {
    let info = []
    const self = this
    switch (attribute) {
    case "current_definition":
      attribute = "Second file"
      break
    case "previous_definition":
      attribute = "First file"
      break
    }

    if (messages instanceof Array) {
      const allMessages = messages
        .map((message, idx) => {
          if (message instanceof Object) {
            return self._humanAttributeError(idx.toString(), message)
          } else {
            return message
          }
        })
        .join(", ")
      info.push(`${attribute}: ${allMessages}`)
    } else if (messages instanceof Object) {
      for (const [child, child_messages] of Object.entries(messages)) {
        info = info.concat(
          self._humanAttributeError(`${attribute}.${child}`, child_messages),
        )
      }
    } else if (messages) {
      info.push(`${attribute}: ${messages}`)
    }

    return info
  }

  _expirationDate() {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    return tomorrow.toISOString()
  }

  copy(event) {
    const link = event.currentTarget
    const url = window.location.href
    navigator.clipboard.writeText(url)

    link.classList.add("active")

    setTimeout(() => {
      link.classList.remove("active")
    }, 2000)
  }
}
