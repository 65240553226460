import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["container", "statusText", "content"]

  connect() {
    this.versionValue = this.contentTarget.dataset.diffInputVersionValue
    this.validationUrlValue = this.element.dataset.fileValidationUrl
    this.type = this.element.dataset.fileValidationType

    const self = this
    if (this.type === "file") {
      document.addEventListener(`diffInput:${this.versionValue}Selected`, (e) => {
        self.validateFile(e.detail.file)
      })
      document.addEventListener("diffInput:invalid", () => {
        self._showValidation({message: "No file detected"})
      })
    } else {
      document.addEventListener(`diffUrlInput:${this.versionValue}`, (e) => {
        self.validateUrl(e.detail.url)
      })
    }
    document.addEventListener("input:reset", this._reset.bind(this))
  }

  validateFile(file) {
    const self = this
    file.text().then(response => {
      const payload = {
        "definition": response
      }

      self._callValidationWith({payload}, file)
    })
  }

  validateUrl(url) {
    const self = this
    const payload = {
      url
    }

    self._callValidationWith({payload}, url)
  }

  _callValidationWith({payload}, spec) {
    this._reset()
    this.containerTarget.classList.add("submitted")
    return this._callApi(
      this.validationUrlValue,
      "POST",
      JSON.stringify(payload)
    ).then(response => {
      switch (response?.status) {
      case 422:
        return response.json().then((body) => Promise.reject(body))
      case 200:
        return response.json().then((body) => {
          this._showValidation(body)
          document.dispatchEvent(
            new CustomEvent(
              `${this.type}:valid`,
              {
                detail: { spec: spec, position: this.versionValue }
              }
            )
          )
        })
      default:
        return Promise.reject()
      }
    }).catch((error) => {
      this._showValidation({errors: true, message: error?.message})
      document.dispatchEvent(
        new CustomEvent(
          `${this.type}:invalid`,
          {
            detail: { spec: spec, position: this.versionValue }
          }
        )
      )
    })
  }

  _callApi(url, method, body = null) {
    return fetch(url, {
      method: method,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: body
    })
  }

  _showValidation(response) {
    let status
    if (!response.specification) {
      this.containerTarget.classList.add("unsupported")
      status = document.createTextNode(response.message)
    } else {
      this.containerTarget.classList.remove("unsupported")
      const type = response.specification.split("/")[0]
      switch(type) {
      case "openapi":
        status = document.createTextNode("OpenAPI File")
        break
      case "asyncapi":
        status = document.createTextNode("AsyncAPI File")
        break
      }
    }

    this.statusTextTarget.innerHTML = ""
    this.statusTextTarget.append(status)
    this.containerTarget.classList.remove("submitted")
    this.containerTarget.classList.add("has-file")
  }

  _reset() {
    this.statusTextTarget.replaceChildren()
    this.containerTarget.classList.remove("has-file")
  }
}
