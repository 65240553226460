import { FileController } from "../../workspace/controllers/file_controller.js"

export default class extends FileController {
  connect() {
    super.connect()
    this.container = this.element.closest(".form-file__container")
    this.versionValue = this.element.dataset.diffInputVersionValue

    document.addEventListener("input:reset", this._reset.bind(this))
  }

  handleChange(e) {
    super.handleChange(e)

    const file = this.fileInputTarget.files.item(0)
    if (file) {
      document.dispatchEvent(new CustomEvent("diffInput:" + this.versionValue + "Selected", { detail: { file: file, position: this.versionValue } }))
      this.container.classList.remove("unsupported")
    } else {
      document.dispatchEvent(new CustomEvent("diffInput:invalid"))
      console.warn("No file detected on the file input change. Try again by drag & dropping the file, or browsing files from your filesystem")
    }
  }

  _reset() {
    this.element.querySelector("input").value = ""
    this.uploadLabelTarget.innerText = this.uploadLabelTarget.getAttribute("data-label")
  }
}
