import { Controller } from "@hotwired/stimulus"

export class FileController extends Controller {
  static targets = ["container", "fileInput", "uploadLabel", "destroyCheckbox"]

  connect() {
    this.initPreventDefaults()
    this.initDragEnterEvents()
    this.initDragLeaveEvents()
    this.initDrop()
    this.initChange()
    this.initRemove()
  }

  initPreventDefaults() {
    this.element.addEventListener("drag", this.preventDefault)
    this.element.addEventListener("dragstart", this.preventDefault)
    this.element.addEventListener("dragend", this.preventDefault)
    this.element.addEventListener("dragover", this.preventDefault)
    this.element.addEventListener("dragenter", this.preventDefault)
    this.element.addEventListener("dragleave", this.preventDefault)
    this.element.addEventListener("drop", this.preventDefault)
  }

  preventDefault(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  initDragEnterEvents() {
    this.element.addEventListener("dragover", this.addDragClass.bind(this))
    this.element.addEventListener("dragenter", this.addDragClass.bind(this))
  }

  addDragClass() {
    this.element.classList.add("drag-over")
  }

  initDragLeaveEvents() {
    this.element.addEventListener("dragleave", this.removeDragClass.bind(this))
    this.element.addEventListener("dragend", this.removeDragClass.bind(this))
    this.element.addEventListener("drop", this.removeDragClass.bind(this))
  }

  removeDragClass() {
    this.element.classList.remove("drag-over")
  }

  initDrop() {
    this.element.addEventListener("drop", this.handleDrop.bind(this))
  }

  handleDrop(e) {
    this.fileInputTarget.files = e.dataTransfer.files
    this.handleChange(e)
  }

  initChange() {
    this.fileInputTarget.addEventListener("change", this.handleChange.bind(this))
  }

  handleChange() {
    this.uploadLabelTarget.innerText = this.fileName()
  }

  fileName() {
    return this.fileInputTarget.value.split(/(\\|\/)/g).pop()
  }

  initRemove() {
    if (this.hasDestroyCheckboxTarget) {
      this.destroyCheckboxTarget.addEventListener("change", this.handleRemove.bind(this))
    }
  }

  handleRemove() {
    this.containerTarget.classList.remove("has-file")
    this.containerTarget.classList.add("empty")
  }
}
