import { Controller } from "@hotwired/stimulus"
import lottie from "lottie-web"
import apiDiffAnimation from "../../animations/api-diff.json"

export default class extends Controller {
  static targets = ["logo"]

  connect() {
    this.logoTarget.innerHTML = ""
    lottie.loadAnimation({
      container: this.logoTarget,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: apiDiffAnimation
    })
  }
}
