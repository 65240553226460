import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileErrorMessage", "urlErrorMessage"]

  connect() {
    document.addEventListener("diff:missingFile", this._showFileErrorMessage.bind(this))
    document.addEventListener("diff:missingUrl", this._showUrlErrorMessage.bind(this))
    document.addEventListener("diff:load", this._showLoading.bind(this))
    document.addEventListener("diff:results", this._showResults.bind(this))
    document.addEventListener("diff:hasChanges", this._setChangesPanel.bind(this))
    document.addEventListener("diff:error", this._showErrorResults.bind(this))
  }

  _showFileErrorMessage() {
    this.fileErrorMessageTarget.innerText = "You must add two valid files"
    this.fileErrorMessageTarget.classList.remove("hidden")
  }

  _showUrlErrorMessage() {
    this.urlErrorMessageTarget.innerText = "You must input two valid URLs"
    this.urlErrorMessageTarget.classList.remove("hidden")
  }

  _showLoading() {
    this.element.classList.add("submitted")
  }

  _showResults() {
    this.element.classList.add("loaded")
  }

  _setChangesPanel() {
    this.element.classList.add("has-changes")
  }

  _showErrorResults() {
    this.element.classList.add("loaded", "submitted--error")
  }

  reset() {
    this.element.classList.remove("submitted", "loaded", "has-changes", "submitted--error")
    document.dispatchEvent(new CustomEvent("input:reset"))
    window.location.hash = ""
    const currentUrl = window.location.href
    history.pushState({path: currentUrl}, "", currentUrl)
  }
}
