import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["urlInput"]

  connect() {
    const currentUrl = new URL(window.location.href)
    this.versionValue = this.element.dataset.diffInputVersionValue
    this.urlInputTarget.value = currentUrl.searchParams.get(this.versionValue)
    this.validate({ target: this.urlInputTarget })

    document.addEventListener("input:reset", this._reset.bind(this))

    document.addEventListener("input:examples", (event) => {
      if (event.detail[this.versionValue]) {
        this.urlInputTarget.value = event.detail[this.versionValue]
        this.validate({ target: this.urlInputTarget })
      }
    })
  }

  validate(event) {
    const value = event.target.value

    if (value.startsWith("http")) {
      document.dispatchEvent(
        new CustomEvent(`diffUrlInput:${this.versionValue}`, {
          detail: { url: value, position: this.versionValue }
        })
      )
    }
  }

  _reset() {
    this.element.querySelector("input").value = ""
  }
}
