import { Controller } from "@hotwired/stimulus"

const apis = {
  "peertube": {
    previous: "https://github.com/Chocobozzz/PeerTube/blob/bdf70330e4d74091ccc9d64534d3f780d5ee8ef4/support/doc/api/openapi.yaml",
    current: "https://github.com/Chocobozzz/PeerTube/blob/7177b46ca1b35aa9d7ed39a06c1dcf41a4fc6180/support/doc/api/openapi.yaml"
  },
  "discourse": {
    previous: "https://github.com/discourse/discourse_api_docs/blob/95294783cc7f4130926bd5e2a67218f567621b93/openapi.json",
    current: "https://github.com/discourse/discourse_api_docs/blob/2607d51622d63f7f3f1e2048f5f6b421c16c465e/openapi.json"
  },
  "zorabots": {
    previous: "https://github.com/bump-sh/examples/raw/fa565b05f3205f35834375cf5129d0a6ca576f7d/hubs/zbos-mqtt/all-asyncapi-source.json",
    current: "https://github.com/bump-sh/examples/raw/920670c907ed083c63786bd37b550400ab36f292/hubs/zbos-mqtt/all-asyncapi-source.json"
  }
}

export default class extends Controller {
  static targets = ["logo"]

  diff(event) {
    const apiName = event.params.api
    document.dispatchEvent(new CustomEvent("input:examples", { detail: { previous: apis[apiName].previous, current: apis[apiName].current }}))
  }
}
